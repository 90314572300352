<template>
    <div class="container">
        <div class="info-container">
            <div class="info-title">
                <span>{{detail.name}}</span>
                <el-button type="primary" @click="handleDialogShow">续期</el-button>
            </div>
            <div class="info-sub">
                <div class="info-sub-item">
                    <span class="key">联系人: </span>
                    <span class="value">{{ detail.contact_name }}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">联系人电话: </span>
                    <span class="value">{{ detail.contact_phone }}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">创建时间: </span>
                    <span class="value">{{ detail.create_time | timeFilter }}</span>
                </div>
                <div class="info-sub-item">
                    <span class="key">过期时间: </span>
                    <span class="value">{{ detail.expiration_time | timeFilter }}</span>
                </div>
            </div>
        </div>
        <div class="tabs">
            <el-tabs class="my-tabs" v-model="activeTab">
                <el-tab-pane label="教学计划" name="plan" v-if="!auth('ADMIN')">
                    <school-plan :school-id="id" v-if="activeTab == 'plan'"/>
                </el-tab-pane>
                <el-tab-pane label="班级管理" name="class">
                    <school-class :school-id="id" :school-name="detail.name" v-if="activeTab == 'class'"/>
                </el-tab-pane>
                <el-tab-pane label="教师管理" name="account">
                    <school-account :school-id="id" v-if="activeTab == 'account'"/>
                </el-tab-pane>
                <el-tab-pane label="学生管理" name="student">
                    <school-student :school-id="id" :org-id='detail.org_id' v-if="activeTab == 'student'"/>
                </el-tab-pane>
                <el-tab-pane label="订阅记录" name="subscribe" v-if="auth('ADMIN')">
                    <school-subscribe :school-id="id" v-if="activeTab == 'subscribe'"/>
                </el-tab-pane>
            </el-tabs>
        </div>
        <el-dialog title="续期" width="504px" :visible.sync="timeVisible">
            <div class="form-item">
                <div class="key">续期时长</div>
                <div class="value">
                    <el-date-picker v-model="subscribeTime" type="date" placeholder="选择日期" value-format="timestamp" @change="timeChange"/>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button @click="timeVisible = false">取消</el-button>
                <el-button type="primary" @click="handleUpdateTime">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Core from '@/core';

export default {
    components: {
        SchoolPlan: () => import('./components/SchoolPlan'),
        SchoolClass: () => import('./components/SchoolClass'),
        SchoolAccount: () => import('./components/SchoolAccount'),
        SchoolStudent: () => import('./components/SchoolStudent'),
        SchoolSubscribe: () => import('./components/SchoolSubscribe'),
    },
    data() {
        return {
            id: '',

            detail: {},
            activeTab: 'plan',

            timeVisible: false,
            subscribeTime: ''
        };
    },
    watch: {},
    computed: {},
    created() {
        this.id = this.$route.query.id || '';
        this.getSchoolDetail();
        if (this.auth('ADMIN')) {
            this.activeTab = 'class'
        }
    },
    mounted() {},
    methods: {
        auth: Core.Utils.auth,
        getSchoolDetail() {
            Core.Api.School.detail(this.id).then((res) => {
                this.detail = res.school
            });
        },

        handleDialogShow() {
            this.timeVisible = true;
            this.subscribeTime = '';
        },
        handleUpdateTime() {
            const currentTime = new Date().valueOf();
            const expirationTime = parseInt((this.subscribeTime - currentTime) / 1000);
            Core.Api.School.renewal(
                this.id,
                expirationTime
            ).then(() => {
                this.$message.success('续期成功');
                this.timeVisible = false;
            })
        },
        timeChange(e) {
            console.log("e", e)
        }
    },
};
</script>

<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
    .info-container {
        width: 100%;
        background: #ffffff;
        box-sizing: border-box;
        padding: 0 30px;
        border-bottom: 0;
        .info-title {
            padding-top: 20px;
            font-size:20px;
            font-weight:500;
            color:$color-black;
            line-height:28px;
            display: flex;
            justify-content: space-between;
        }
        .info-sub {
            @include flex(row, space-between, center);
            flex-wrap: wrap;
            padding-top: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;
            &-item{
                width: 250px;
                span{
                    font-size: $font-size-base;
                    font-weight: 400;
                    line-height: 20px;
                }
                .key {
                    color:$color-black-light;
                }
                .value {
                    color:$color-black;
                }
            }
        }
    }
    .tabs {
        width: 100%;
        box-sizing: border-box;
    }
}
</style>